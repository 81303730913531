import request from '../../utils/request.js'
// 菜单管理
// 初始化
export function menuInit(){
    return request({
        url:'/api/menu/tree',
        method:'get'
    });
}

// 菜单新增
export function addMenu(data){
    return request({
        url:'/api/menu',
        method:'post',
        data
    })
}
// 查看详情
export function detail(id){
    return request({
        url:`/api/menu/${id}`,
        method:'get'
    });
}
//删除
export function del(id){
    return request({
        url:`/api/menu/${id}`,
        method:'delete'
    });
}
// 编辑初始化
export function updateInit(id){
    return request({
        url:`/api/menu/updateInit/${id}`,
        method:'get'
    })
}
// 编辑修改
export function edit(data){
    return request({
        url:'/api/menu',
        method:'put',
        data
    });
}